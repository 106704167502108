import React from "react";
import "./landing.css";
const landing = () => {
  const downloadResume = () => {
    var resumeUrl = process.env.REACT_APP_URL + "/files/resume.pdf";
    window.open(resumeUrl, "_blank").focus();
  };

  const hireme = () => {
    var protocol = window.location.protocol;
    var current_location = window.location.host;
    window.location.href = protocol + "//" + current_location + "/#contact";
  };

  function calculateDateDifferenceInYears(startDate) {
    // Convert the input startDate to a Date object
    const start = new Date(startDate);
    const end = new Date(); // Current date

    if (isNaN(start)) {
      throw new Error("Invalid date input");
    }

    // Calculate year difference
    let yearsDifference = end.getFullYear() - start.getFullYear();

    // Calculate month difference
    const monthsDifference = end.getMonth() - start.getMonth();
    const totalMonthsDifference = yearsDifference * 12 + monthsDifference;

    // Adjust years based on month difference
    const remainderMonths = totalMonthsDifference % 12;

    if (remainderMonths >= 6) {
      yearsDifference += 0.5;
    } else if (remainderMonths > 0) {
      yearsDifference = Math.floor(yearsDifference);
    }

    return yearsDifference;
  }

  return (
    <div className="container">
      <div className="row page-size">
        <div className="banner-text col md-6">
          <h1 className="landing-headline-1">Hi! I'm Sushant Kumar</h1>
          <h1 className="landing-headline-2">Software Engineer</h1>
          <p>
            Software Engineer with{" "}
            {calculateDateDifferenceInYears("2020-08-01")}+ years of experience
            in backend development and full-stack development using Java,
            Nodejs, and React. I have done some work on Machine learning and
            Deep learning as well. I love solving real life problems.
          </p>
          <p>
            <button className="hire-me-button" onClick={hireme}>
              Hire Me
            </button>
            <button
              className="explore-projects-button"
              onClick={downloadResume}
            >
              Download Resume
            </button>
          </p>
        </div>
        <div className="col md-6">
          <img src="images/banner.png"></img>
        </div>
      </div>
    </div>
  );
};

export default landing();
